import React, { Component } from "react";
import ReactDOM from "react-dom";

class Map extends Component {
  loadMap() {
    //if google property exists
    if (this.props && this.props.google) {
      const { google } = this.props;
      const maps = google.maps;
      const mapRef = this.refs.map;
      const node = ReactDOM.findDOMNode(mapRef);

      let zoom = 15;
      let lat = 37.774929;
      let lng = -122.419416;
      const center = new maps.LatLng(lat, lng);
      const mapConfig = Object.assign(
        {},
        {
          center: center,
          zoom: zoom,
          mapTypeId: google.maps.MapTypeId.SATELLITE,
          backgroundColor: "#505050",
          disableDefaultUI: true
        }
      );
      this.map = new maps.Map(node, mapConfig);
      this.props.changeReady(true);
    }
  }

  componentDidMount() {
    this.loadMap();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.google !== this.props.google) {
      this.loadMap();
    }

    if (prevProps.loc !== this.props.loc) {
      this.changeMapLoc(this.props.loc);
    }
  }

  changeMapLoc(newLoc) {
    const { google } = this.props;
    const { lat, lng } = newLoc;
    var position = new google.maps.LatLng(lat, lng);
    this.map.panTo(position);
  }
  handleRef = () => {
    const { loc } = this.props;
    const mapRef = this.refs.map;
    if (loc) {
      if (loc.hasOwnProperty("airquality")) {
        const { aqi } = loc.airquality;

        if (aqi < 50 && aqi > 0) {
          mapRef.className = "";
          void mapRef.offsetWidth;
          mapRef.classList.add("green");
        } else if (aqi > 50 && aqi < 100) {
          mapRef.className = "";
          void mapRef.offsetWidth;
          mapRef.classList.add("yellow");
        } else if (100 < aqi) {
          mapRef.className = "";
          void mapRef.offsetWidth;
          mapRef.classList.add("red");
        }
      }
    }
  };
  render() {
    const { loc } = this.props;

    const renderOverlayClass = currLocation => {
      if (currLocation) {
        if (currLocation.hasOwnProperty("airquality")) {
          const { aqi } = currLocation.airquality;

          if (aqi < 50 && aqi > 0) {
            return "green";
          } else if (aqi > 50 && aqi < 100) {
            return "yellow";
          } else if (100 < aqi) {
            return "red";
          }
        }
      }
    };
    return (
      <div id="ev-map-component" ref="map" className={renderOverlayClass(loc)}>
        Loading map...
      </div>
    );
  }
}

export default Map;
