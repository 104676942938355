import React, { Component } from "react";

import evLogo from "../assets/ev-logo.svg";

class Header extends Component {
  render() {
    return (
      <header id="ev-header">
        <nav id="ev-nav">
          <div className="ev-nav-list-container">
            <ul id="ev-nav-list">
              <li className="ev-nav-item">
                <a href="/">
                  <i className="menu-circle" />
                  <span>Map</span>
                </a>
              </li>
            </ul>
          </div>
          <div className="ev-nav-brand">
            <a href="/">
              <img src={evLogo} alt="Evelozcity Logo" className="brand-logo" />
            </a>
          </div>
        </nav>
      </header>
    );
  }
}

export default Header;
