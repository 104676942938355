import React, { Component } from "react";

import { connect } from "react-redux";
import "./scss/App.scss";

import Header from "./common/Header";
import Footer from "./common/Footer";
import {
  changeLocation,
  changeReady,
  changeTextVisible
} from "./actions/mapActions";
import MapContainer from "./map/MapContainer";
import Preloader from "./common/Preloader";

class App extends Component {
  constructor(props) {
    super(props);
    //local app state to determine if preloader should be unmounted
    this.state = {
      renderPreloader: true
    };
  }

  //check if textVisible was changed to true - unmount the preloader
  componentDidUpdate(prevProps) {
    if (!prevProps.textVisible && this.props.textVisible) {
      setTimeout(() => this.setState({ renderPreloader: false }), 350);
    }
  }
  render() {
    // get mapped props
    const {
      loc,
      ready,
      textVisible,
      changeReady,
      changeLocation,
      changeTextVisible
    } = this.props;

    const { renderPreloader } = this.state;

    return (
      <div className="App">
        <Header />
        <MapContainer
          loc={loc}
          ready={ready}
          changeReady={changeReady}
          changeLocation={changeLocation}
          textVisible={textVisible}
        />
        <Footer />
        {renderPreloader ? (
          <Preloader ready={ready} changeTextVisible={changeTextVisible} />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    loc: state.map.loc,
    ready: state.map.ready,
    textVisible: state.map.textVisible
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeLocation: newLoc => dispatch(changeLocation(newLoc)),
    changeReady: readyBool => dispatch(changeReady(readyBool)),
    changeTextVisible: visibleBool => dispatch(changeTextVisible(visibleBool))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
