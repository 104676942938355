import React, { Component } from "react";

class MapContent extends Component {
  //if component succesfully mounted, load autocomplete
  componentDidMount() {
    this.props.loadAutoComplete(this.refs.autocomplete);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.google !== this.props.google) {
      this.props.loadAutoComplete(this.refs.autocomplete);
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (this.props.textVisible !== nextProps.textVisible) {
      this.startFadeAnim();
    }
  }

  startFadeAnim() {
    const { text1, text2, text3, text4 } = this.refs;
    const textArray = [text1, text2, text3, text4];
    for (var i = 0; i < textArray.length; i++) {
      let delayTime = 50 * i;
      this.mountClass(textArray[i], "fadeIn", delayTime);
    }
  }

  mountClass(node, classname, delay) {
    setTimeout(() => {
      node.classList.add(classname);
    }, delay);
  }

  render() {
    const disableDefault = e => e.preventDefault();
    const { sendPlacePrediction, loadingLoc, cardReady, loc } = this.props;

    const skeletonAQI = (obj, key) => {
      if (obj) {
        if (obj.airquality[key]) {
          return obj.airquality[key];
        } else {
          return "loading";
        }
      }
    };

    const renderInfoText = locObj => {
      const textObj = {
        good:
          "Air quality is considered satisfactory, and air pollution poses little or no risk.",
        moderate:
          "Air quality is acceptable; however, for some pollutants there may be a moderate health concern for a very small number of individualds sensitie to air pollution.",
        bad:
          "Everyone may begin experiencing health effects; members of sensitive groups may experience more serious health effects."
      };

      if (locObj) {
        const { aqi } = locObj.airquality;

        switch (true) {
          case 50 > aqi > 0:
            return textObj.good;
          case 100 > aqi:
            return textObj.moderate;
          case 100 < aqi:
            return textObj.bad;
          default:
            return "N/A";
        }
      }
    };

    return (
      <div id="ev-map-content">
        <div className="ev-map-top">
          <p ref="text1">Real-time Air Quality Checker</p>
          <h1 ref="text2">High standards. Low Pollution.</h1>
          <p ref="text3">
            Our goal is to become the automotive leader in sustainability by
            eliminating highly toxic gas-powered vehicle emissions. By reducing
            high levels of toxic air pollution in densely populated cities,
            EVeleocity will be better for human health.
          </p>
          <form id="aqi-location-form" ref="text4" onSubmit={disableDefault}>
            <input
              type="text"
              ref="autocomplete"
              placeholder="Enter City or Address"
            />
            <button
              type="button"
              onClick={sendPlacePrediction}
              className={loadingLoc ? "loading" : ""}
            >
              <span className="search-text">Check Quality</span>
              <span className="search-icon" />
            </button>
          </form>
        </div>
        <div className="ev-map-bottom">
          <div
            className={"ev-map-bottom-card " + (cardReady ? "show" : "hide")}
          >
            <div className="ev-map-bottom-header">
              {skeletonAQI(loc, "category")}
            </div>
            <div className="ev-map-bottom-score">{skeletonAQI(loc, "aqi")}</div>
            <div className="ev-map-bottom-subheader">
              Air Quality Index Score
            </div>
            <div className="ev-map-bottom-small">{renderInfoText(loc)}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default MapContent;
