import React, { Component } from 'react';


class Footer extends Component {
  render() {
    return (
      <footer id="ev-footer">
        <nav id = "ev-footer-nav">
          <ul id = "ev-footer-list">
            <li><a href = "/">Contact</a></li>
            <li><a href = "/">Terms</a></li>
            <li><a href = "/">FAQ</a></li>
            <li><a href = "/">Locations</a></li>
          </ul>
        </nav>
      </footer>
    );
  }
}

export default Footer;
