import React, { Component } from "react";

import { TweenMax, Power2, Power4, TimelineMax, Back } from "gsap/TweenMax";

class PreloaderAnimator {
  constructor(props) {
    this.props = props;

    const colA = [".a1", ".a2", ".a3", ".a4", ".a5", ".a6", ".a7"];
    const colB = [".b1", ".b2", ".b3", ".b4", ".b5", ".b6", ".b7"];
    const colC = [".c1", ".c2", ".c3", ".c4", ".c5", ".c6", ".c7"];
    const colD = [".d1", ".d2", ".d3", ".d4", ".d5", ".d6", ".d7"];
    const colE = [".e1", ".e2", ".e3", ".e4", ".e5", ".e6", ".e7"];

    TweenMax.set("rect, .preloader", {
      transformOrigin: "center"
    });
    TweenMax.lagSmoothing(0);
    const dur1 = 1.2;
    const dur2 = 0.22;
    const dur3 = 3;

    const ease1 = Power2.easeIn;
    const ease2 = Power4.easeOut;

    const tl = new TimelineMax({
      repeat: 0,
      yoyo: true,
      onComplete: function() {
        props.removePreloader(this);
      }
    });

    tl.timeScale(2);

    tl.add("l0");

    let rects = document.querySelectorAll("rect");
    rects = Array.from(rects);
    rects = this.shuffle(rects);
    tl.staggerFrom(
      rects,
      0.35,
      { scale: 0.5, opacity: 0, ease: Back.easeOut },
      0.02,
      "l0"
    );

    tl.add("l1");

    const _x = 7.5;
    const mx = m => `-=${_x * m}`;
    const px = m => `+=${_x * m}`;

    tl.to(colA, dur1, { x: mx(2), ease: ease1 }, "l1");
    tl.to(colB, dur1, { x: mx(1), ease: ease1 }, "l1");
    tl.to(colD, dur1, { x: px(1), ease: ease1 }, "l1");
    tl.to(colE, dur1, { x: px(2), ease: ease1 }, "l1");

    const _y = 4.3;
    const my = m => `-=${_y * m}`;
    const py = m => `+=${_y * m}`;

    tl.to(
      [".a1", ".b1", ".c1", ".d1", ".e1"],
      dur1,
      { y: my(3), ease: ease1 },
      "l1"
    );

    tl.to(
      [".a2", ".b2", ".c2", ".d2", ".e2"],
      dur1,
      { y: my(2), ease: ease1 },
      "l1"
    );

    tl.to(
      [".a3", ".b3", ".c3", ".d3", ".e3"],
      dur1,
      { y: my(1), ease: ease1 },
      "l1"
    );
    // --
    tl.to(
      [".a5", ".b5", ".c5", ".d5", ".e5"],
      dur1,
      { y: py(1), ease: ease1 },
      "l1"
    );

    tl.to(
      [".a6", ".b6", ".c6", ".d6", ".e6"],
      dur1,
      { y: py(2), ease: ease1 },
      "l1"
    );

    tl.to(
      [".a7", ".b7", ".c7", ".d7", ".e7"],
      dur1,
      { y: py(3), ease: ease1 },
      "l1"
    );

    tl.add("l2");

    tl.to(
      [".a1", ".b1", ".c1", ".d1", ".e1"],
      dur2,
      { y: py(3), ease: ease2 },
      "l2"
    );

    tl.to(
      [".a2", ".b2", ".c2", ".d2", ".e2"],
      dur2,
      { y: py(2), ease: ease2 },
      "l2"
    );

    tl.to(
      [".a3", ".b3", ".c3", ".d3", ".e3"],
      dur2,
      { y: py(1), ease: ease2 },
      "l2"
    );

    tl.to(
      [".a5", ".b5", ".c5", ".d5", ".e5"],
      dur2,
      { y: my(1), ease: ease2 },
      "l2"
    );

    tl.to(
      [".a6", ".b6", ".c6", ".d6", ".e6"],
      dur2,
      { y: my(2), ease: ease2 },
      "l2"
    );

    tl.to(
      [".a7", ".b7", ".c7", ".d7", ".e7"],
      dur2,
      { y: my(3), ease: ease2 },
      "l2"
    );

    tl.to(
      [".a2", ".b2", ".c2", ".d2", ".e2", ".a3", ".b3", ".c3", ".d3", ".e3"],
      dur2,
      { opacity: 0, ease: ease2 },
      "l2"
    );

    tl.to(
      [".b5", ".c5", ".d5", ".e5", ".b6", ".c6", ".d6", ".e6"],
      dur2,
      { opacity: 0, ease: ease2 },
      "l2"
    );

    tl.to([".a5", ".a6"], dur2, { scaleY: 1.8, ease: ease2 }, "l2");

    tl.to(colA, dur2, { x: px(2.63), ease: ease2 }, "l2");
    tl.to(colB, dur2, { x: px(1.045), ease: ease2 }, "l2");
    tl.to(colD, dur2, { x: mx(1.045), ease: ease2 }, "l2");
    tl.to(
      [".e1", ".e2", ".e3", ".e5", ".e6", ".e7"],
      dur2,
      { x: mx(2.63), ease: ease2 },
      "l2"
    );

    tl.to(".e4", dur2, { x: mx(3.8), ease: ease2 }, "l2");

    tl.to(colC, dur2, { scaleX: 1.74, ease: ease2 }, "l2");

    tl.to(".preloader", dur2, { scale: 0.8, ease: ease2 }, "l2");
    tl.to(".preloader", dur3, { scale: 1, ease: Power2.easeOut });
  }
  checkReady(isReady) {
    if (isReady) {
      this.restart();
    }
  }
  shuffle(array) {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }
}

class Preloader extends Component {
  constructor(props) {
    super(props);

    this.rect1 = null;
    document.body.addEventListener("click", () => {});
  }

  componentDidMount() {
    new PreloaderAnimator({
      ...this.props,
      removePreloader: this.removePreloader.bind(this)
    });
  }

  removePreloader(ctx) {
    if (!this.props.ready) {
      ctx.restart();
      return;
    } else {
      this.props.changeTextVisible(true);
    }
    this.refs.preloaderCont.classList.add("fadeOut");
  }

  render() {
    return (
      <div id="ev-preloader" ref="preloaderCont">
        <svg className="preloader" viewBox="0 0 138.7 130.6">
          <rect className="a1" x="32.7" y="13.1" width="11.2" height="11.2" />
          <rect className="a2" x="32.7" y="28.7" width="11.2" height="11.2" />
          <rect className="a3" x="32.7" y="44.2" width="11.2" height="11.2" />
          <rect className="a4" x="32.7" y="59.7" width="11.2" height="11.2" />
          <rect className="a5" x="32.7" y="75.3" width="11.2" height="11.2" />
          <rect className="a6" x="32.7" y="90.8" width="11.2" height="11.2" />
          <rect className="a7" x="32.7" y="106.3" width="11.2" height="11.2" />
          <rect className="b1" x="48.2" y="13.1" width="11.2" height="11.2" />
          <rect className="b2" x="48.2" y="28.7" width="11.2" height="11.2" />
          <rect className="b3" x="48.2" y="44.2" width="11.2" height="11.2" />
          <rect className="b4" x="48.2" y="59.7" width="11.2" height="11.2" />
          <rect className="b5" x="48.2" y="75.3" width="11.2" height="11.2" />
          <rect className="b6" x="48.2" y="90.8" width="11.2" height="11.2" />
          <rect className="b7" x="48.2" y="106.3" width="11.2" height="11.2" />
          <rect className="c1" x="63.7" y="13.1" width="11.2" height="11.2" />
          <rect className="c2" x="63.7" y="28.7" width="11.2" height="11.2" />
          <rect className="c3" x="63.7" y="44.2" width="11.2" height="11.2" />
          <rect className="c4" x="63.7" y="59.7" width="11.2" height="11.2" />
          <rect className="c5" x="63.7" y="75.3" width="11.2" height="11.2" />
          <rect className="c6" x="63.7" y="90.8" width="11.2" height="11.2" />
          <rect className="c7" x="63.7" y="106.3" width="11.2" height="11.2" />
          <rect className="d1" x="79.3" y="13.1" width="11.2" height="11.2" />
          <rect className="d2" x="79.3" y="28.7" width="11.2" height="11.2" />
          <rect className="d3" x="79.3" y="44.2" width="11.2" height="11.2" />
          <rect className="d4" x="79.3" y="59.7" width="11.2" height="11.2" />
          <rect className="d5" x="79.3" y="75.3" width="11.2" height="11.2" />
          <rect className="d6" x="79.3" y="90.8" width="11.2" height="11.2" />
          <rect className="d7" x="79.3" y="106.3" width="11.2" height="11.2" />
          <rect className="e1" x="94.8" y="13.1" width="11.2" height="11.2" />
          <rect className="e2" x="94.8" y="28.7" width="11.2" height="11.2" />
          <rect className="e3" x="94.8" y="44.2" width="11.2" height="11.2" />
          <rect className="e4" x="94.8" y="59.7" width="11.2" height="11.2" />
          <rect className="e5" x="94.8" y="75.3" width="11.2" height="11.2" />
          <rect className="e6" x="94.8" y="90.8" width="11.2" height="11.2" />
          <rect className="e7" x="94.8" y="106.3" width="11.2" height="11.2" />
        </svg>
      </div>
    );
  }
}

export default Preloader;
