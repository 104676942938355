//emulates es2015 enviroment(allows usage of promises etc)
import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import configureStore from "./store/configureStore";

import "./index.scss";
import App from "./App";

const store = configureStore({
  map: { loc: null, ready: false, textVisible: false }
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
