export const changeLocation = loc => {
  return {
    type: "CHANGE_LOCATION",
    loc
  };
};

export const changeReady = readyBool => {
  return {
    type: "CHANGE_READY",
    ready: readyBool
  };
};

export const changeTextVisible = visibleBool => {
  return {
    type: "CHANGE_TEXT_VISIBLE",
    textVisible: visibleBool
  };
};
