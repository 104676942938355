export default (state = [], action) => {
  switch (action.type) {
    case "CHANGE_LOCATION":
      return {
        ...state,
        loc: action.loc
      };
    case "CHANGE_READY":
      return {
        ...state,
        ready: action.ready
      };
    case "CHANGE_TEXT_VISIBLE":
      return {
        ...state,
        textVisible: action.textVisible
      };
    default:
      return state;
  }
};
