import React, { Component } from "react";
import Map from "./Map";
import MapContent from "./MapContent";
import GoogleApiComponent from "../helpers/GoogleApiComponent";

import axios from "axios";

class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardReady: false,
      currInput: "",
      loadingLoc: false
    };
  }

  geoCallback = position => {
    const { google } = this.props;
    const geoLatLng = new google.maps.LatLng(
      position.coords.latitude,
      position.coords.longitude
    );
    this.geocoder.geocode(
      {
        latLng: geoLatLng
      },
      (results, status) => {
        if (status === "OK") {
          let place = results[0];

          this.generateLocObj(place).then(response => {
            const { aqi, category } = response.data.response[0].periods[0];
            const { lat, lng } = place.geometry.location;
            const { formatted_address } = place;

            this.props.changeLocation({
              lat: lat(),
              lng: lng(),
              airquality: {
                aqi,
                category
              }
            });
            this.setState({ cardReady: true, loadingLoc: false });
            this.inputRef.value = formatted_address;
          });
        }
      }
    );
  };

  renderMapComponent() {
    const { loc, google, changeReady } = this.props;
    const { cardReady } = this.state;
    return (
      <Map
        loc={loc}
        google={google}
        changeReady={changeReady}
        cardReady={cardReady}
      />
    );
  }

  loadAutoComplete = input => {
    //if google property exists
    if (this.props && this.props.google) {
      this.inputRef = input;
      const { google } = this.props;
      const places = google.maps.places;

      var options = {
        types: ["(cities)"]
      };

      this.autocompleteInst = new places.Autocomplete(this.inputRef, options);
      this.autocompleteSrvc = new places.AutocompleteService();
      this.geocoder = new google.maps.Geocoder();
      this.autocompleteInst.setFields([
        "address_components",
        "geometry",
        "formatted_address"
      ]);
      this.autocompleteInst.addListener("place_changed", this.handleLocChange);
    }
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(this.geoCallback);
    }
  };

  handleLocChange = () => {
    this.setState({ loadingLoc: true });
    let unmountDelay = 0;
    let place = this.autocompleteInst.getPlace();
    if (!place.address_components) {
      this.sendPlacePrediction();
      return;
    }

    if (this.state.cardReady) {
      this.setState({ cardReady: false });
      unmountDelay = 250;
    }
    setTimeout(() => {
      this.generateLocObj(place).then(response => {
        const { aqi, category } = response.data.response[0].periods[0];
        const { lat, lng } = place.geometry.location;
        this.props.changeLocation({
          lat: lat(),
          lng: lng(),
          airquality: {
            aqi,
            category
          }
        });
        this.setState({ cardReady: true, loadingLoc: false });
      });
    }, unmountDelay);
  };
  sendPlacePrediction = evt => {
    this.setState({ loadingLoc: true });
    this.autocompleteSrvc.getPlacePredictions(
      {
        input: this.inputRef.value,
        types: ["(cities)"]
      },
      this.processPlacePrediction
    );
  };
  processPlacePrediction = (predictions, status) => {
    if (!predictions) {
      this.setState({ loadingLoc: false });
      return;
    }

    var address = predictions[0].description;
    this.geocoder.geocode({ address }, (results, status) => {
      if (status === "OK") {
        let place = results[0];

        this.generateLocObj(place).then(response => {
          const { aqi, category } = response.data.response[0].periods[0];
          const { lat, lng } = place.geometry.location;
          const { formatted_address } = place;

          this.props.changeLocation({
            lat: lat(),
            lng: lng(),
            airquality: {
              aqi,
              category
            }
          });
          this.setState({ cardReady: true, loadingLoc: false });
          this.inputRef.value = formatted_address;
        });
      }
    });
  };
  generateLocObj(place) {
    return new Promise((resolve, reject) => {
      let { address_components, geometry } = place;
      let coordinates = {
        city: address_components[0].long_name,
        lat: geometry.location.lat(),
        lng: geometry.location.lng()
      };

      this.getAQI(coordinates)
        .then(locObj => {
          resolve(locObj);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  getAQI(coordinates) {
    return new Promise((resolve, reject) => {
      var aerisEP = "https://api.aerisapi.com/airquality";
      var aerisID = "ESW3ktIcN7BdhcMyzQSHR";
      var aerisSecret = "AM3aZeOxnxcDGcW4vXYE4N23NQyGGhnIAIIyrKvS";
      if (coordinates.lat && coordinates.lng) {
        var compiledEP = `${aerisEP}/${coordinates.lat},${
          coordinates.lng
        }?client_id=${aerisID}&client_secret=${aerisSecret}`;
        axios
          .get(compiledEP)
          .then(response => {
            resolve(response);
          })
          .catch(err => {
            reject(err);
          });
      } else {
        reject("No lat/lng given.");
      }
    });
  }
  render() {
    const {
      ready,
      loaded,
      textVisible,
      loc,
      google,
      changeLocation
    } = this.props;
    return (
      <section className="ev-map-container">
        <MapContent
          ready={ready}
          textVisible={textVisible}
          google={google}
          changeLocation={changeLocation}
          loc={loc}
          loadAutoComplete={this.loadAutoComplete}
          sendPlacePrediction={this.sendPlacePrediction}
          loadingLoc={this.state.loadingLoc}
          cardReady={this.state.cardReady}
        />
        {loaded ? this.renderMapComponent() : <h1>LOADING</h1>}
      </section>
    );
  }
}

export default GoogleApiComponent({
  apiKey: "AIzaSyAvcmkyqUQ3NlWyagihj0CNLCaGBt3zhqU"
})(MapContainer);
